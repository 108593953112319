import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'next/navigation';
import { Product } from 'shared/types/product/Product';
import {
  PDP_PRODUCT_ADDED_TO_CART,
  QUICK_VIEW_PRODUCT_ADDED_TO_CART,
  PDP_VIEWED,
  PDP_VIEWED_AFTER_SEARCH,
  QUICK_VIEW_PRODUCT_ADDED_TO_CART_AFTER_SEARCH,
  PDP_PRODUCT_ADDED_TO_CART_AFTER_SEARCH,
  DEFAULT_CURRENCY,
  PADI_CHECKOUT_NAME,
  PADI_BRAND,
  EVENT_ITEM_CATEGORY_CERT_CARDS,
} from 'helpers/constants/events';

interface TrackingItem {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_variant?: string;
  price: number;
  quantity: number;
}

interface Options {
  inModalVersion?: boolean;
}

const useTrack = ({ inModalVersion }: Options) => {
  const searchParams = useSearchParams();
  const isAfterSearch = searchParams.get('sr');
  const lastProductId = useRef<string>(undefined);

  // Helper to safely fetch attribute values with a default value
  const getAttributeValue = (attr: any, defaultValue = '') => {
    if (Array.isArray(attr)) return attr[0]?.label ?? defaultValue;
    else return attr?.label ?? defaultValue;
  };

  // Helper to create a TrackingItem from a Product
  const createTrackingItem = (product: Product, count?: number): TrackingItem => {
    const productId = product.productId ?? '';
    const variant = product.variants[0];
    const price = variant.price?.centAmount ? variant.price?.centAmount / 100 : 0;
    const cardType = getAttributeValue(variant.attributes?.['card-type']);
    const data = {
      item_id: productId,
      item_name: product.name ?? '',
      item_brand: PADI_BRAND,
      item_category: EVENT_ITEM_CATEGORY_CERT_CARDS,
      item_category2: cardType ?? '',
      item_variant: '',
      price: price,
      quantity: count ?? 1,
    };
    return data;
  };

  const trackView = useCallback(
    async (product: Product) => {
      if (product && product.productId !== lastProductId.current) {
        const trackingItem = createTrackingItem(product);
        const variant = product.variants[0];

        gtag('event', isAfterSearch ? PDP_VIEWED_AFTER_SEARCH : PDP_VIEWED, {
          padi_checkout_name: PADI_CHECKOUT_NAME,
          currency: DEFAULT_CURRENCY,
          value: variant.price?.centAmount ? variant.price.centAmount / 100 : 0,
          items: [trackingItem],
        });

        lastProductId.current = product.productId;
      }
    },
    [isAfterSearch],
  );

  const trackAddToCart = useCallback(
    async (product: Product, count?: number) => {
      const trackingItem = createTrackingItem(product, count);
      const variant = product.variants[0];
      const itemCount = count ?? 1;
      const totalValue = itemCount * (variant.price?.centAmount ? variant.price.centAmount / 100 : 0);

      const eventName = inModalVersion
        ? isAfterSearch
          ? QUICK_VIEW_PRODUCT_ADDED_TO_CART_AFTER_SEARCH
          : QUICK_VIEW_PRODUCT_ADDED_TO_CART
        : isAfterSearch
        ? PDP_PRODUCT_ADDED_TO_CART_AFTER_SEARCH
        : PDP_PRODUCT_ADDED_TO_CART;

      gtag('event', eventName, {
        padi_checkout_name: PADI_CHECKOUT_NAME,
        currency: variant.price?.currencyCode ?? DEFAULT_CURRENCY,
        value: totalValue,
        items: [trackingItem],
      });
    },
    [isAfterSearch, inModalVersion],
  );

  return { trackAddToCart, trackView };
};

export default useTrack;
